<script setup>
import { useI18n } from '@/util';
import CustomfieldAddOrEditDialogFieldTypes from '../CustomfieldAddOrEditDialogFieldTypes.vue';
import CustomfieldAddOrEditAdvancedFormula from './CustomfieldAddOrEditAdvancedFormula.vue';
import CustomfieldAddOrEditAdvancedFormulaAdvancedOptions from './CustomfieldAddOrEditAdvancedFormulaAdvancedOptions.vue';
import CustomfieldAddOrEditAdvancedFormulaInfo from './CustomfieldAddOrEditAdvancedFormulaInfo.vue';
import CustomfieldAddOrEditAdvancedFormulaOptions from './CustomfieldAddOrEditAdvancedFormulaOptions.vue';

const props = defineProps({
  customfield: {
    type: Object,
    required: true,
  },
  customfieldType: {
    type: String,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
  entity: {
    type: String,
    required: true,
  },
  canAddFormula: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:customfield', 'update:customfieldType']);

// We need the full ref here for the editor to work
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const formulaRef = ref(null);
const showOptionsPanel = shallowRef(true);
const customfield = shallowRef(props.customfield);

const { t } = useI18n();

const isFormulaValid = computed(() => Boolean(formulaRef.value?.formulaString?.trim()));

function toggleColumnPanel() {
  showOptionsPanel.value = !showOptionsPanel.value;
}
const optionDetails = shallowRef(null);
function updateFieldName(selected) {
  optionDetails.value = selected;
}
</script>

<template>
  <div class="flex h-full border-y">
    <div class="flex">
      <CustomfieldAddOrEditAdvancedFormulaInfo v-if="showOptionsPanel" :option="optionDetails" />
      <div class="relative">
        <button
          v-LsdTooltip="{
            text: showOptionsPanel ? t('Collapse') : t('Expand'),
          }"
          type="button"
          data-identifier="app-nav__caret"
          :class="[
            'absolute right-full top-7 flex size-5 translate-x-1/2 transform items-center justify-center rounded-full border border-solid bg-default transition duration-500 ease-in-out',
            { '-rotate-180': !showOptionsPanel },
          ]"
          @click.stop="toggleColumnPanel"
        >
          <LscIcon size="sm" icon="lsi-collapse" class="text-subtle transition-transform hover:scale-110" />
        </button>
        <CustomfieldAddOrEditAdvancedFormulaOptions
          :fields="formulaRef?.fields"
          :editor="formulaRef?.editor"
          @showOption="updateFieldName"
        />
      </div>
    </div>

    <div class="w-96 flex-1 p-4">
      <div class="flex flex-col gap-x-6 gap-y-4">
        <div class="flex gap-3">
          <VTextField v-model.trim="customfield.name" :label="t('Field title')" density="compact" autofocus required />
          <CustomfieldAddOrEditDialogFieldTypes
            :modelValue="customfieldType"
            :disabled="isEditing"
            :canAddFormula="canAddFormula"
            @update:modelValue="emit('update:customfieldType', $event)"
          />
        </div>

        <CustomfieldAddOrEditAdvancedFormula
          ref="formulaRef"
          :modelValue="customfield.formula"
          :editing="isEditing"
          :entity="entity"
          @update:modelValue="emit('update:customfield', { ...customfield, formula: $event })"
        />
        <CustomfieldAddOrEditAdvancedFormulaAdvancedOptions
          :modelValue="customfield"
          :isFormulaValid="isFormulaValid"
          @update:modelValue="emit('update:customfield', $event)"
        />
      </div>
    </div>
  </div>
</template>
